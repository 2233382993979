import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['popover', 'item', 'button', 'buttontext', 'formfield']
  static values = { open: Boolean }

  connect() {
    this.selected_id = this.data.get("selected")

    this.openValue = false
    this._refresh()
  }

  button_click(event) {
    event.preventDefault()
    this._toggle()
  }

  window_click(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  select_item(event) {
    this.selected_id = event.currentTarget.id.replace("user_", "")
    console.log(event.currentTarget.dataset.username)
    this.buttontextTarget.innerHTML = event.currentTarget.dataset.username
    this.formfieldTarget.value = this.selected_id
    this._refresh()
    this.openValue = false
  }

  _toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if(this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    this.popoverTarget.classList.add("opacity-100")
    this.popoverTarget.classList.remove("opacity-0", "hidden")
  }

  _hide() {
    this.popoverTarget.classList.add("opacity-0", "hidden")
    this.popoverTarget.classList.remove("opacity-100")
  }

  _refresh() {
    this.itemTargets.forEach((item, i) => {
      var spans = item.getElementsByTagName("span")
      var check_span = spans[spans.length - 1]

      if(item.id == "user_"+this.selected_id) {
        console.log("selected item: " +item)

        check_span.classList.remove("hidden")
      } else {
        check_span.classList.add("hidden")
      }
    });
  }
}
