import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["clipboard"]

  copy(event) {
    event.preventDefault()

    const textToCopy = this.clipboardTarget.value
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        this.notify_success()
      })
      .catch((error) => {
        this.notify_failure()
        console.log(error)
      })
  }

  notify_success() {
    var html = `
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end mt-20 px-4 py-6 sm:items-start sm:p-6">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">URL copied to clipboard!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `
    this.notify(html)
  }

  notify_failure() {
    var html = `
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end mt-20 px-4 py-6 sm:items-start sm:p-6">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <div class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-red-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                </svg>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">Failed to copy URL!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `

    this.notify(html)
  }

  notify(html) {
    var notificationDiv = document.createElement('div');
    notificationDiv.innerHTML = html;
    document.body.appendChild(notificationDiv);
    // delete notificationDiv after 5 seconds
    setTimeout(() => {
      notificationDiv.remove()
    }, 5000);
  }
}