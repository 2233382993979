// Start StimulusJS
import { Application } from "@hotwired/stimulus"
// import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start();
//const context = require.context("controllers", true, /.js$/);
//application.load(definitionsFromContext(context));

// Import and register all TailwindCSS Components
// import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register('dropdown', Dropdown)
// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)

import { Alert } from "tailwindcss-stimulus-components"
application.register('alert', Alert)

import { Tabs } from "tailwindcss-stimulus-components"
application.register('tabs', Tabs)

import { Modal } from "tailwindcss-stimulus-components"
application.register('modal', Modal)

// import Flatpickr
import Flatpickr from 'stimulus-flatpickr'

// Import style for flatpickr
require("flatpickr/dist/flatpickr.css")

// Manually register Flatpickr as a stimulus controller
application.register('flatpickr', Flatpickr)

import { Autocomplete } from 'stimulus-autocomplete'
application.register('autocomplete', Autocomplete)

import { Autosave } from "tailwindcss-stimulus-components"
application.register('autosave', Autosave)

// import NestedForm from "stimulus-rails-nested-form"
//
// application.register("nested-form", NestedForm)
