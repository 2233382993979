export default class Bridge {
  static register(token) {
    const element = document.head.querySelector(`meta[name="csrf-token"]`)
    const csrfToken = element.getAttribute("content")

    fetch("/notification_tokens", {
      body: JSON.stringify({ token: token }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    })
  }
}