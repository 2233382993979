import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { autohideafter: Number }

  initialize() {
    this.hide()
  }

  connect() {
    this.element.classList.add("transition")
    this.element.classList.add("duration-500")
    setTimeout(() => {
      this.show()
    }, 200)

    if(this.autohideafterValue > 0) {
      setTimeout(() => {
        this.hide()
      }, this.autohideafterValue)
    }

  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)

  }

  show() {
    this.element.classList.remove("opacity-0")
    this.element.classList.add("opacity-95")
  }

  hide() {
    this.element.classList.remove("opacity-95")
    this.element.classList.add("opacity-0")
  }
}
