import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['item']

  connect() {
    this.activeClasses = (this.data.get('activeClasses') || '').split(' ')
    this.inactiveClasses = (this.data.get('inactiveClasses') || '').split(' ')
    this.checkedClasses = (this.data.get('checkedClasses') || '').split(' ')
    this.uncheckedClasses = (this.data.get('uncheckedClasses') || '').split(' ')

    this._first_load()
  }

  change(event) {
    event.preventDefault()
    var label = event.currentTarget
    var checkbox = label.getElementsByTagName("input")[0]

    this._changeTo(checkbox)
  }

  _changeTo(checkbox) {
    checkbox.checked = true
    // console.log(checkbox)
    this._refresh()
  }

  _refresh() {
    this.itemTargets.forEach((item, i) => {
      var checkbox = item.getElementsByTagName("input")[0]
      var divs = item.getElementsByTagName("div")
      var lastDiv = divs[divs.length-1]
      if(checkbox.checked) {
        if(this.checkedClasses != "") { lastDiv.classList.add(...this.checkedClasses) }
        if(this.uncheckedClasses != "") { lastDiv.classList.remove(...this.uncheckedClasses) }
        // if(this.activeClasses != "") { item.classList.add(...this.activeClasses) }
        // if(this.inactiveClasses != "") { item.classList.remove(...this.inactiveClasses) }
      } else {
        if(this.checkedClasses != "") { lastDiv.classList.remove(...this.checkedClasses) }
        if(this.uncheckedClasses != "") { lastDiv.classList.add(...this.uncheckedClasses) }
        // if(this.activeClasses != "") { item.classList.remove(...this.activeClasses) }
        // if(this.inactiveClasses != "") { item.classList.add(...this.inactiveClasses) }
      }
    });
  }

  _first_load() {
    this.itemTargets.forEach((item, i) => {
      // console.log(item)
      var checkbox = item.getElementsByTagName("input")[0]
      var divs = item.getElementsByTagName("div")
      var lastDiv = divs[divs.length-1]
      // console.log(lastDiv)
      if(checkbox.checked) {
        console.log("checked item is " + checkbox)
        // console.log(this.checkedClasses)
        if(this.checkedClasses != "") { lastDiv.classList.add(...this.checkedClasses) }
        if(this.uncheckedClasses != "") { lastDiv.classList.remove(...this.uncheckedClasses) }
        if(this.activeClasses != "") { item.classList.add(...this.activeClasses) }
        if(this.inactiveClasses != "") { item.classList.remove(...this.inactiveClasses) }
      } else {
        if(this.checkedClasses != "") { lastDiv.classList.remove(...this.checkedClasses) }
        if(this.uncheckedClasses != "") { lastDiv.classList.add(...this.uncheckedClasses) }
        if(this.activeClasses != "") { item.classList.remove(...this.activeClasses) }
        if(this.inactiveClasses != "") { item.classList.add(...this.inactiveClasses) }
      }
    });
  }
}
