import LocalTime from "local-time"

LocalTime.config.i18n["de"] = {
  date: {
    dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    abbrDayNames: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    monthNames: ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    abbrMonthNames: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    yesterday: "gestern",
    today: "heute",
    tomorrow: "morgen",
    on: "am {date}",
    formats: {
      "default": "%e. %B %Y",
      thisYear: "%e. %B"
    }
  },
  time: {
    am: "vormittags",
    pm: "nachmittags",
    singular: "einer {time}",
    singularAn: "einer {time}",
    elapsed: "vor {time}",
    second: "Sekunde",
    seconds: "Sekunden",
    minute: "Minute",
    minutes: "Minuten",
    hour: "Stunde",
    hours: "Stunden",
    formats: {
      "default": "%H:%M Uhr"
    }
  },
  datetime: {
    at: "{date} um {time}",
    formats: {
      "default": "%e. %B %Y um %H:%M %Z"
    }
  }
}

LocalTime.config.locale = document.documentElement.lang

LocalTime.start()