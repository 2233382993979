// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static values = { open: Boolean }

  connect() {
    this.targetMenuSelector = this.data.get('activeTarget') || "#mobilemenu"
    this.targetMenu = document.querySelector(this.targetMenuSelector)
    this.toggleClass = this.data.get('class') || 'hidden'
    this.visibleClass = this.data.get('visibleClass') || null
    this.invisibleClass = this.data.get('invisibleClass') || null
    this.activeClass = this.data.get('activeClass') || null
    this.enteringClass = this.data.get('enteringClass') || null
    this.leavingClass = this.data.get('leavingClass') || null

    this._hide()
  }

  show() {
    this._show()
  }

  hide() {
    this._hide()
  }

  // openValueChanged() {
  //   console.log("openValueChanged -> " + this.openValue)
  //   if (this.openValue) {
  //     this._show()
  //   } else {
  //     this._hide()
  //   }
  // }

  _show(cb) {
    setTimeout(
      (() => {
        this.targetMenu.classList.remove(this.toggleClass)
        this.element.setAttribute("aria-expanded", "true")
        this._enteringClassList[0].forEach(
          (klass => {
            this.targetMenu.classList.add(klass)
          }).bind(this),
        )

        this._activeClassList[0].forEach(klass => {
          this.activeTarget.classList.add(klass)
        })
        this._invisibleClassList[0].forEach(klass => this.targetMenu.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => {
          this.targetMenu.classList.add(klass)
        })
        setTimeout(
          (() => {
            this._enteringClassList[0].forEach(klass => this.targetMenu.classList.remove(klass))
          }).bind(this),
          this.enterTimeout[0],
        )

        if (typeof cb == 'function') cb()
      }).bind(this),
    )
  }

  _hide(cb) {
    setTimeout(
      (() => {
        this.element.setAttribute("aria-expanded", "false")
        this._invisibleClassList[0].forEach(klass => this.targetMenu.classList.add(klass))
        this._visibleClassList[0].forEach(klass => this.targetMenu.classList.remove(klass))
        this._activeClassList[0].forEach(klass => this.activeTarget.classList.remove(klass))
        this._leavingClassList[0].forEach(klass => this.targetMenu.classList.add(klass))
        setTimeout(
          (() => {
            this._leavingClassList[0].forEach(klass => this.targetMenu.classList.remove(klass))
            if (typeof cb == 'function') cb()

            this.targetMenu.classList.add(this.toggleClass)
          }).bind(this),
          this.leaveTimeout[0],
        )
      }).bind(this),
    )
  }

  get _activeClassList() {
    return !this.activeClass
      ? [[], []]
      : this.activeClass.split(',').map(classList => classList.split(' '))
  }

  get _visibleClassList() {
    return !this.visibleClass
      ? [[], []]
      : this.visibleClass.split(',').map(classList => classList.split(' '))
  }

  get _invisibleClassList() {
    return !this.invisibleClass
      ? [[], []]
      : this.invisibleClass.split(',').map(classList => classList.split(' '))
  }

  get _enteringClassList() {
    return !this.enteringClass
      ? [[], []]
      : this.enteringClass.split(',').map(classList => classList.split(' '))
  }

  get _leavingClassList() {
    return !this.leavingClass
      ? [[], []]
      : this.leavingClass.split(',').map(classList => classList.split(' '))
  }

  get enterTimeout() {
    let timeout = this.data.get('enterTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  get leaveTimeout() {
    let timeout = this.data.get('leaveTimeout') || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }
}
