// Entry point for the build script in your package.json
import "./controllers"

//const images = require.context('../images', true)

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "./channels"

Rails.start()
ActiveStorage.start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// import "controllers"
import "./src/stimulus-components"

import Bridge from "./turbo/bridge.js"
window.bridge = Bridge;

import "./src/local_time"

import "trix"
import "@rails/actiontext"
