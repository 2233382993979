// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
// import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
// import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  // locales = {
  //   fr: French,
  //   en: english
  // };
  //
  static targets = ['clear']

  connect() {
    //define locale and global flatpickr settings for all datepickers
    // this.config = {
    //   locale: this.locale,
    //   altInput: true,
    //   showMonths: 2
    // };
    super.connect();

    this.clearTarget.addEventListener("click", this._clear.bind(this))
  }
  // initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    // this.config = {
    //   locale: French
    // }
  //   this.config = {}
  //   console.log("done")
  // }

  // get locale() {
  //   if (this.locales && this.data.has("locale")) {
  //     return this.locales[this.data.get("locale")];
  //   } else {
  //     return "";
  //   }
  // }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  _clear(fp) {
    console.log("clear")
    console.log(this.fp)
    this.fp.clear()
    this.fp.close()
  }
}
