import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nav-bar-button"
export default class extends Controller {
  static values = {
    url: String,
    symbol: String,
    jsaction: String
  }

  connect() {
    // console.log("url: ", this.urlValue)
    // console.log("symbol: ", this.symbolValue)
    // console.log("title: ", this.titleValue)
    // console.log("jsAction: ", this.jsactionValue)
    var message = { action: "nav-bar-button" }
    if(this.symbolValue) {
      message["symbol"] = this.symbolValue
    }
    if(this.titleValue) {
      message["title"] = this.titleValue
    }
    if(this.urlValue) {
      message["url"] = this.urlValue
    }
    if(this.jsactionValue) {
      message["jsAction"] = this.jsactionValue
    }

    if(this.symbolValue || this.titleValue) {
      window.webkit?.messageHandlers?.nativeApp.postMessage(message)
    }
  }
}
